/* eslint-disable @typescript-eslint/no-explicit-any */
import { $PROXY } from 'solid-js';
import { useFragment } from './__generated__';
import type { FragmentType } from './__generated__';
import type { DocumentTypeDecoration } from '@graphql-typed-document-node/core';

// return non-nullable if `fragmentType` is non-nullable
export function createFragment<
	TType,
	Props extends Record<K, FragmentType<DocumentTypeDecoration<TType, any>>>,
	K extends keyof Props,
>(documentNode: DocumentTypeDecoration<TType, any>, props: Props, key: K): TType;
// return nullable if `fragmentType` is undefined
export function createFragment<
	TType,
	Props extends Record<K, FragmentType<DocumentTypeDecoration<TType, any>> | undefined>,
	K extends keyof Props,
>(documentNode: DocumentTypeDecoration<TType, any>, props: Props, key: K): TType | undefined;
// return nullable if `fragmentType` is nullable
export function createFragment<
	TType,
	Props extends Record<K, FragmentType<DocumentTypeDecoration<TType, any>> | null>,
	K extends keyof Props,
>(documentNode: DocumentTypeDecoration<TType, any>, props: Props, key: K): TType | null;
// return nullable if `fragmentType` is nullable or undefined
export function createFragment<
	TType,
	Props extends Record<K, FragmentType<DocumentTypeDecoration<TType, any>> | null | undefined>,
	K extends keyof Props,
>(documentNode: DocumentTypeDecoration<TType, any>, props: Props, key: K): TType | null | undefined;
// return array of non-nullable if `fragmentType` is array of non-nullable
export function createFragment<
	TType,
	Props extends Record<K, Array<FragmentType<DocumentTypeDecoration<TType, any>>>>,
	K extends keyof Props,
>(documentNode: DocumentTypeDecoration<TType, any>, props: Props, key: K): Array<TType>;
// return array of nullable if `fragmentType` is array of nullable
export function createFragment<
	TType,
	Props extends Record<K, Array<FragmentType<DocumentTypeDecoration<TType, any>>> | null | undefined>,
	K extends keyof Props,
>(documentNode: DocumentTypeDecoration<TType, any>, props: Props, key: K): Array<TType> | null | undefined;
// return readonly array of non-nullable if `fragmentType` is array of non-nullable
export function createFragment<
	TType,
	Props extends Record<K, ReadonlyArray<FragmentType<DocumentTypeDecoration<TType, any>>>>,
	K extends keyof Props,
>(documentNode: DocumentTypeDecoration<TType, any>, props: Props, key: K): ReadonlyArray<TType>;
// return readonly array of nullable if `fragmentType` is array of nullable
export function createFragment<
	TType,
	Props extends Record<K, ReadonlyArray<FragmentType<DocumentTypeDecoration<TType, any>>> | null | undefined>,
	K extends keyof Props,
>(documentNode: DocumentTypeDecoration<TType, any>, props: Props, key: K): ReadonlyArray<TType> | null | undefined;

// return non-nullable if `fragmentType` is non-nullable
export function createFragment<TType, Props extends FragmentType<DocumentTypeDecoration<TType, any>>>(
	documentNode: DocumentTypeDecoration<TType, any>,
	props: Props,
): TType;
// return nullable if `fragmentType` is undefined
export function createFragment<TType, Props extends undefined>(
	documentNode: DocumentTypeDecoration<TType, any>,
	props: Props,
): undefined;
// return nullable if `fragmentType` is nullable
export function createFragment<TType, Props extends null>(
	documentNode: DocumentTypeDecoration<TType, any>,
	props: Props,
): null;
// return nullable if `fragmentType` is nullable or undefined
export function createFragment<TType, Props extends null | undefined>(
	documentNode: DocumentTypeDecoration<TType, any>,
	props: Props,
): null | undefined;
// return array of non-nullable if `fragmentType` is array of non-nullable
export function createFragment<TType, Props extends Array<FragmentType<DocumentTypeDecoration<TType, any>>>>(
	documentNode: DocumentTypeDecoration<TType, any>,
	props: Props,
): Array<TType>;
// return array of nullable if `fragmentType` is array of nullable
export function createFragment<TType, Props extends Array<null | undefined>>(
	documentNode: DocumentTypeDecoration<TType, any>,
	props: Props,
): null | undefined;
// return readonly array of non-nullable if `fragmentType` is array of non-nullable
export function createFragment<TType, Props extends ReadonlyArray<FragmentType<DocumentTypeDecoration<TType, any>>>>(
	documentNode: DocumentTypeDecoration<TType, any>,
	props: Props,
): ReadonlyArray<TType>;
// return readonly array of nullable if `fragmentType` is array of nullable
export function createFragment<TType, Props extends ReadonlyArray<null | undefined>>(
	documentNode: DocumentTypeDecoration<TType, any>,
	props: Props,
): null | undefined;

export function createFragment<
	TType,
	Props extends
		| Record<
				K,
				| FragmentType<DocumentTypeDecoration<TType, any>>
				| Array<FragmentType<DocumentTypeDecoration<TType, any>>>
				| ReadonlyArray<FragmentType<DocumentTypeDecoration<TType, any>>>
				| null
				| undefined
		  >
		| FragmentType<DocumentTypeDecoration<TType, any>>
		| Array<FragmentType<DocumentTypeDecoration<TType, any>>>
		| ReadonlyArray<FragmentType<DocumentTypeDecoration<TType, any>>>
		| null
		| undefined,
	K extends keyof Props,
>(
	documentNode: DocumentTypeDecoration<TType, any>,
	props: Props,
	key?: K,
): TType | Array<TType> | ReadonlyArray<TType> | null | undefined {
	return new Proxy(
		{
			get(property: string | number | symbol) {
				const data = useFragment(
					documentNode,
					key
						? // @ts-ignore
							props[key]
						: props,
				) as object;
				return data && property in (data as object) ? data[property as keyof typeof data] : undefined;
			},
			has(property: string | number | symbol) {
				const data = useFragment(
					documentNode,
					key
						? // @ts-ignore
							props[key]
						: props,
				);
				return data && property in (data as object);
			},
			keys() {
				const data = useFragment(
					documentNode,
					key
						? // @ts-ignore
							props[key]
						: props,
				);
				return Object.keys(data as object);
			},
		},
		propTraps,
	) as TType | Array<TType> | ReadonlyArray<TType>;
}

function trueFn() {
	return true;
}

const propTraps: ProxyHandler<{
	get: (k: string | number | symbol) => any;
	has: (k: string | number | symbol) => boolean;
	keys: () => Array<string>;
}> = {
	get(_, property, receiver) {
		if (property === $PROXY) return receiver;
		return _.get(property);
	},
	has(_, property) {
		return _.has(property);
	},
	set: trueFn,
	deleteProperty: trueFn,
	getOwnPropertyDescriptor(_, property) {
		return {
			configurable: true,
			enumerable: true,
			get() {
				return _.get(property);
			},
			set: trueFn,
			deleteProperty: trueFn,
		};
	},
	ownKeys(_) {
		return _.keys();
	},
};
